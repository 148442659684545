import React, {useRef, useState} from "react";
import {colors} from "../constants/colors";
import PageContent from "./PageContent";
import CustomHeading from "./CustomHeading";
import {Button, FormControl, Input, InputLabel, withStyles} from "@material-ui/core";
import styled from "styled-components";
import {Route, Switch, useHistory} from "react-router-dom";
import loader from "../img/loader2.json";
import Lottie from "lottie-react";
import InlineLink from "./InlineLink";
import {scrollToMenuTop} from "../hooks/ScrollToTopOfMenu";

const CustomForm = styled.form`
  color: white;
`

const FinePrint = styled.p`
  color: white`;

const Email = styled.a`
  color: white;
  text-decoration: underline;
`;

const StyledFormControl = withStyles(() => ({
    root: {
        color: `${colors.hex.white}`,
        "& .MuiInputBase-input": {
            fontFamily: `'GTAmerica', Arial, serif`,
        },
        fontFamily: `'GTAmerica', Arial, serif`,
        "& .MuiInputLabel-formControl": {
            color: `${colors.hex.white}`,
            height: 60,
            "& input": {
                textAlign: "right"
            }
        },
        "& .MuiInput-formControl": {
            color: `${colors.hex.white}`,
        },
        "& .MuiInput-underline:before": {
            borderBottom: `1px solid ${colors.hex.white}`,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: `2px solid ${colors.hex.white}`,
        },
        "& .MuiInput-underline:after": {
            borderBottom: `${colors.hex.white}`,
        },
        marginBottom: "40px",
    }
}))(FormControl);

const LottieLoaderWrap = styled.div`
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
`;

const StyledFormButton = withStyles(() => ({
    root: {
        background: "none",
        border: "2px solid white",
        borderRadius: "23px",
        height: "46px",
        width: "190px",
        boxShadow: "none",
        fontFamily: `'GTAmerica', Arial, serif`,
        "&.MuiButton-containedPrimary:hover": {
            background: `${colors.hex.grey}`,
            boxShadow: "none",
        }
    }
}))(Button);

export default function Rsvp() {
    const formRef = useRef<HTMLFormElement>(null)
    const browserHistory = useHistory();
    const [showLoader, setShowLoader] = useState(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const scriptUrl = "https://script.google.com/macros/s/AKfycbwwK3fYCXqLJYq6qDIUWfJqyfxm2sde-nIkvMJQUPLJ-l_jXOo/exec"
        setShowLoader(true);
        if (formRef && formRef.current) {
            const data = new FormData(formRef.current)

            fetch(scriptUrl, {
                method: 'POST',
                mode: 'no-cors',
                body: data,
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    console.log(response);
                    setShowLoader(false);
                    browserHistory.replace("/rsvp/yay")
                    scrollToMenuTop();
                })
                .catch(e => {
                    setShowLoader(false);
                    browserHistory.replace("/rsvp/boo")
                    scrollToMenuTop();
                });
        }
    };

    return (
        <Switch>
            <Route exact path="/rsvp">
                <PageContent background={colors.hex.green}>
                    <CustomHeading customHeadingText="BOAT RIDE RSVP"/>
                    <CustomHeading customHeadingText="KINDLY RESPOND BY"/>
                    <CustomHeading customHeadingText="SEPTEMBER 1, 2021."/>
                    <CustomForm onSubmit={handleSubmit} ref={formRef}>
                        <StyledFormControl fullWidth={true}>
                            <InputLabel htmlFor="my-input">FIRST NAME</InputLabel>
                            <Input required name="firstName" type="text" aria-describedby="first name"/>
                        </StyledFormControl>
                        <StyledFormControl fullWidth={true}>
                            <InputLabel htmlFor="my-input">LAST NAME</InputLabel>
                            <Input required name="lastName" type="text" aria-describedby="last name"/>
                        </StyledFormControl>
                        <StyledFormControl fullWidth={true}>
                            <InputLabel htmlFor="my-input">EMAIL</InputLabel>
                            <Input required name="email" type="email" aria-describedby="email address"/>
                        </StyledFormControl>
                        <StyledFormButton
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Submit RSVP!
                        </StyledFormButton>
                    </CustomForm>
                    <FinePrint>Please RSVP separately for each individual in your party. Budget a full day, January 6th,
                        2022 if you'd like to attend. Details including a
                        full itinerary and departure location will be shared via email. If you have any questions,
                        please <Email href="mailto:caitlin.benny.wedding@gmail.com">email</Email> us.</FinePrint>
                    {showLoader && <LottieLoaderWrap>
                        <Lottie animationData={loader}/>
                    </LottieLoaderWrap>}
                </PageContent>
            </Route>
            <Route exact path="/rsvp/yay">
                <PageContent background={colors.hex.green}>
                    <CustomHeading customHeadingText="THANK YOU FOR"/>
                    <CustomHeading customHeadingText="REPLYING! WE CANT"/>
                    <CustomHeading customHeadingText="WAIT TO SEE YOU!"/>
                    <br/>
                    <br/>
                    <InlineLink href="/rsvp" target="_self">Need to RSVP another person?</InlineLink>
                </PageContent>
            </Route>
            <Route exact path="/rsvp/boo">
                <PageContent background={colors.hex.green}>
                    <CustomHeading customHeadingText="DRATS! SOMETHING"/>
                    <CustomHeading customHeadingText="BROKE SO TELL"/>
                    <CustomHeading customHeadingText="BENNY TO FIX IT"/>
                </PageContent>
            </Route>
        </Switch>
    );
}