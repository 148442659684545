import React from "react";
import styled from 'styled-components'
import std from "../img/std.jpg";

const SaveTheDateWrap = styled.div`
  width: 100%;
  max-width: 700px;
  margin: auto;
`

const SaveTheDateInnerWrap = styled.div`
  display: block;
  position: relative;
`

const SaveTheDateImage = styled.img.attrs({
    src: `${std}`
})`
  width: 100%;
`;

const PhotoCredit = styled.p`
  font-size: 1vmin;
  text-align: right;
`;

export default function SaveTheDate() {
    return (
        <SaveTheDateWrap>
            <SaveTheDateInnerWrap>
                <SaveTheDateImage/>
            </SaveTheDateInnerWrap>
            <PhotoCredit>photo by billy buck</PhotoCredit>
        </SaveTheDateWrap>
    );
}
