import React, {useRef} from "react";
import styled from "styled-components";
import {media} from "../mixins/media";
import useComponentSize, {Size} from "../hooks/PageResize";
import {times} from "lodash";
import {CaitlinAndBennyCurvedVerticalSVG} from "./CaitlinAndBennyCurvedVerticalSVG";

const SidebarCurvedTextWrap = styled.div`
  max-width: 112px;
  grid-area: sidebar;
  grid-column: ${(props: SidebarCurvedTextProps) => props.left ? "1 / 1" : " 3 / 3"};
  padding: 0 5px;

  ${media.sm} {
    padding: 0;
  }
`
const CurvyText = styled.div`
  transform: ${(props: SidebarCurvedTextProps) => props.rotated ? "rotate(180deg)" : "rotate(0deg)"}
`

export interface SidebarCurvedTextProps {
    rotated?: boolean;
    left?: boolean;
    curvedTextColor?: string;
    contentWrapRef: React.RefObject<HTMLDivElement>;
}

export default function SidebarCurvedText(props: SidebarCurvedTextProps) {
    const pageContentTrueSize: Size = useComponentSize(props.contentWrapRef);
    const curvySVGSizerRef = useRef(null);

    return (
        <SidebarCurvedTextWrap {...props}>
            <CurvyText {...props}>
                <CaitlinAndBennyCurvedVerticalSVG
                    color={props.curvedTextColor} height="50%" ref={curvySVGSizerRef}/>
                {times(getRepeatTimes(pageContentTrueSize, curvySVGSizerRef), (idx) => <CaitlinAndBennyCurvedVerticalSVG
                    key={idx}
                    color={props.curvedTextColor}
                    height="50%"/>)}
            </CurvyText>
        </SidebarCurvedTextWrap>
    );

    function getRepeatTimes(size: Size, curvySVGSizerRef: React.RefObject<any>) {
        if (size && size.height && size.width && curvySVGSizerRef.current) {
            const numberOfRepeats = Math.ceil(size.height / curvySVGSizerRef.current.clientHeight);
            return numberOfRepeats - 1;
        }
        return 3;
    }
}