import React from 'react';
import './App.scss';
import styled, {ThemeProvider} from "styled-components";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./components/Home";
import Travel from "./components/Travel";
import TopLogo from "./components/TopLogo";
import Menu from "./components/Menu";
import AboutUs from './components/AboutUs';
import Rsvp from "./components/Rsvp";
import Reception from "./components/Reception";
import Registry from "./components/Registry";
import {spacing} from "./constants/spacing";
import InlineLink from "./components/InlineLink";
import {colors} from "./constants/colors";

const PageWrap = styled.div`
  background-color: white;
  font-family: 'GTAmerica', Arial, serif;
  color: black;
  display: grid;
  gap: ${spacing.gap.sm};
  grid-template-areas:
    "header"
    "nav"
    "content"
    "footer";
  padding-bottom: ${spacing.gap.sm};
`

const AppWrap = styled.div`
  text-align: center;
`;

const Header = styled.header`
  grid-area: header;
`

const Nav = styled.nav`
  grid-area: nav;
`

const Content = styled.article`
  grid-area: content;
`

const Footer = styled.footer`
  grid-area: footer;
  font-size: 1.5vmin;
`;


function App() {
    return (
        <AppWrap>
            <Router>
                <PageWrap>
                    <Header id="mainHeader">
                        <TopLogo/>
                    </Header>
                    <Nav id="mainNav">
                        <Menu/>
                    </Nav>
                    <Content>
                        <Switch>
                            <Route exact path="/">
                                <Home/>
                            </Route>
                            <Route path="/travel">
                                <Travel/>
                            </Route>
                            <Route path="/about-us">
                                <AboutUs/>
                            </Route>
                            <Route path="/registry">
                                <Registry/>
                            </Route>
                            <Route path="/rsvp">
                                <Rsvp/>
                            </Route>
                            <Route path="/reception">
                                <Reception/>
                            </Route>
                        </Switch>
                    </Content>
                    <Footer>
                        <ThemeProvider theme={{main: `${colors.hex.black}`}}>
                            <InlineLink
                                href="mailto:caitlin.benny.wedding@gmail.com">CAITLIN.BENNY.WEDDING@GMAIL.COM</InlineLink>
                        </ThemeProvider>
                    </Footer>
                </PageWrap>
            </Router>
        </AppWrap>
    );
}

export default App;
