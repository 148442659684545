import React from "react";
import styled, {ThemeProvider} from 'styled-components'
import {colors} from "../constants/colors";
import img1 from "../img/CB_engagement_7.jpg";
import img2 from "../img/CB_engagement_16.jpg";
import img3 from "../img/CB_engagement_17.jpg";
import img4 from "../img/CB_engagement_19.jpg";
import img5 from "../img/CB_engagement_23.jpg";
import img6 from "../img/CB_engagement_22.jpg";
import img7 from "../img/IMG_3355 1.jpg";
import img8 from "../img/CB_engagement_21.jpg";
import Image from 'material-ui-image'
import AspectRatio from "react-aspect-ratio";
import CustomHeading from "./CustomHeading";
import PageContent from "./PageContent";

const PhotosWrap = styled.div`
  width: 100%;
  text-align: center;
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WeddingPhotoImg = styled.div`
  width: calc(100% - 20px);
  max-width: 450px;
  display: inline-block;
  margin-right: 20px;
`;

const WeddingPhotoMiddleImg = styled.div`
  width: 75%;
  max-width: 600px;
  margin: 20px auto;
`
const TwoCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  max-width: 900px;
  margin: auto;
  justify-content: space-between;
`
const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 10px);
  align-self: stretch;
  justify-content: space-between;
`
const RightCol = styled.div`
  display: flex;
  flex: 0 0 calc(50% - 10px);
  flex-direction: column;
`
export default function AboutUs() {
    return (
        <PageContent background={colors.hex.sand}>
            <div>
                <CustomHeading customHeadingText="Caitlin and Benny"/>
                <CustomHeading customHeadingText="f o r e v e r"/>

                <p>Caitlin Schooley and Benny Phanichkul first met in Seattle at a vintage clothing store where
                    Caitlin
                    was working in 2010. Seattle was smaller back then and their paths kept crossing throughout the
                    city
                    at shows, art openings and parties. It wasn’t until their friend Rachel Wianecki reconnected
                    them 3
                    years later that they started dating. Thanks Rach!</p>

                <p>They had an instant connection over their mutual love of Kate Bush and Arthur Russell, and the
                    rest
                    was history.</p>

                <p>Over the years they have traveled to countless countries together, bought a house, raised two
                    pets,
                    and mastered the art of karaoke.</p>

                <p>They're so excited about the next chapter of their lives together and are grateful to have a loving
                    community of friends and family to celebrate with in their favorite beachside town.</p>
            </div>
            <PhotosWrap>
                <Top>
                    <WeddingPhotoImg>
                        <Image aspectRatio={1080 / 1620} src={img1} alt={"c+b 1"}/>
                    </WeddingPhotoImg>
                    <WeddingPhotoImg>
                        <Image aspectRatio={1080 / 1620} src={img2} alt={"c+b 2"}/>
                    </WeddingPhotoImg>
                </Top>

                <WeddingPhotoMiddleImg>
                    <Image aspectRatio={1620 / 1080} src={img3} alt={"c+b 3"}/>
                </WeddingPhotoMiddleImg>

                <TwoCol>
                    <LeftCol>
                        <AspectRatio
                            ratio={1620 / 1296}
                            style={{
                                maxWidth: '100%',
                            }}
                        ><img src={img4} style={{
                            width: '100%',
                        }} alt={"c+b 4"}/></AspectRatio>
                        <AspectRatio
                            ratio={1620 / 1296}
                            style={{
                                maxWidth: '100%',
                            }}
                        ><img src={img6} style={{
                            width: '100%',
                        }} alt={"c+b 5"}/></AspectRatio>
                        <AspectRatio
                            ratio={433 / 326}
                            style={{
                                maxWidth: '100%',
                            }}
                        ><img src={img7} style={{
                            width: '100%',
                        }} alt={"c+b 6"}/></AspectRatio>
                    </LeftCol>
                    <RightCol>
                        <AspectRatio
                            ratio={1296 / 1620}
                            style={{
                                maxWidth: '100%',
                                marginBottom: '20px',
                            }}
                        ><img src={img5} style={{
                            width: '100%',
                        }} alt={"c+b 7"}/></AspectRatio>
                        <AspectRatio
                            ratio={1296 / 1620}
                            style={{
                                maxWidth: '100%',
                            }}
                        ><img src={img8} style={{
                            width: '100%',
                        }} alt={"c+b 8"}/></AspectRatio>
                    </RightCol>
                </TwoCol>
            </PhotosWrap>
        </PageContent>
    );
}
