import React from "react";
import styled from 'styled-components'
import {colors} from "../constants/colors";


const StyledInlineLink = styled.a`
  color: ${props => props.theme.main};
  text-decoration: underline;
`;


StyledInlineLink.defaultProps = {
    theme: {
        main: `${colors.hex.white}`
    }
}

interface InlineLinkProps {
    href: string;
    target?: string;
}

export default function InlineLink(props: React.PropsWithChildren<InlineLinkProps>) {

    return (
        <StyledInlineLink href={props.href} target={props.target || "_blank"}
                          rel="noopener noreferrer">{props.children}</StyledInlineLink>
    );
}
