// https://github.com/styled-components/styled-components/issues/2303

const customMediaQuery = (minWidth: number) =>
    `@media (min-width: ${minWidth}px)`;

export const media = {
    custom: customMediaQuery,
    xs: customMediaQuery(480),
    sm: customMediaQuery(576),
    md: customMediaQuery(768),
    lg:customMediaQuery( 992),
    xl: customMediaQuery(1200),
    xxl: customMediaQuery(1600),
};