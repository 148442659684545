export const spacing = {
    pageWrapWidth: '1400px',
    gap: {
        xs: '10px',
        sm: '20px',
        md: '30px',
        lg: '40px',
        xl: '80px',
    },
    gutterPadding: {
        xs: '10px',
        sm: '20px',
        md: '30px',
        lg: '40px',
        xl: '80px',
    }
};