import React from "react";

export default function TravelSVG() {
    return (
        <svg width="100%" height="auto" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path d="M5.12186 17.8307L22.108 15.4872C23.2472 15.33 24.0226 14.2408 23.7729 13.1184C23.0772 9.99318 20.9901 4.56044 14.9471 5.41435C10.7003 6.01439 8.02963 13.1221 6.98888 15.749C6.64 16.6299 6.17579 17.1724 5.71301 17.5058C4.73509 18.2101 3.35862 17.8256 2.84384 16.7357C2.33213 15.6535 1.82437 12.9828 3.0564 9.86924C4.63918 5.86636 7.55232 3.54545 11.3033 3.2147" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M5.64006 17.7592C5.37316 17.4124 4.13611 16.2385 4.32456 15.841C4.54029 15.3852 5.02821 15.0585 5.24395 14.6027C5.46413 14.1352 5.36525 13.6088 5.21254 13.1312C5.05984 12.6536 4.8548 12.1771 4.87657 11.6765C4.89736 11.1759 5.21988 10.6307 5.7681 10.4996C6.18733 10.3993 6.64507 10.5563 7.04951 10.4147C7.55114 10.2384 7.74733 9.6703 7.66769 9.2028C7.58805 8.7353 7.30988 8.32143 7.14925 7.87419C6.98848 7.42595 6.96834 6.87756 7.3352 6.52209C7.93608 5.93849 9.06386 6.27653 9.82314 5.86792C10.4679 5.52156 10.6338 4.77791 10.8268 4.1355C11.0208 3.49296 11.4675 2.758 12.2092 2.72635C12.974 2.69452 13.5291 3.45479 14.2941 3.43909C14.8931 3.42711 15.36 2.94781 15.933 2.77689C16.4644 2.61878 17.0815 2.75977 17.4453 3.12144C17.8091 3.48311 18.8718 5.31002 18.6398 5.765" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M10.7475 14.4855L11.0068 13.8551" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M18.0835 9.12451L18.3821 9.73746" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M16.1 12.7315L15.5044 12.4018" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M13.3003 8.61649L13.8989 9.12848" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M20.3647 12.4308L20.6102 13.259" stroke="#FF5100" stroke-width="1.5" stroke-miterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="22.962" height="15.499" fill="white" transform="translate(0.0488281 3.64182) rotate(-7.85513)"/>
                </clipPath>
            </defs>
        </svg>
    );
}
