export const colors = {
    hex: {
        black: '#000000',
        blue: '#A6DADC',
        green: '#98A581',
        grey: '#e0e0e0',
        terracotta: '#FF5100',
        sand: '#DECBB2',
        white: '#FFFFFF',
    },
};