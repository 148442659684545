import React from "react";
import {colors} from "../constants/colors";
import PageContent from "./PageContent";
import InlineLink from "./InlineLink";

export default function Registry() {
    return (
        <PageContent background={colors.hex.blue}>
            <p>We are registered at</p>
            <p><InlineLink href="https://www.zola.com/registry/bennyandcaitlin">Zola</InlineLink></p>

            <p>In addition to our registry, we've provided a list of charities for friends and family to donate to in
                lieu
                of a physical gift.</p>

            <p><InlineLink href="https://www.treehouseforkids.org">Treehouse</InlineLink></p>
            <p><InlineLink href="https://waterfirst.ngo/about-us">Water First</InlineLink></p>
            <p><InlineLink href="http://www.equalrightswashington.org">Equal Rights Washington</InlineLink></p>
            <p><InlineLink href="https://www.roomtoread.org">Room to Read</InlineLink></p>
            <p><InlineLink href="https://www.aclu-wa.org">ACLU of Washington</InlineLink></p>
            <p><InlineLink href="https://ncascades.org">North Cascades Institute</InlineLink></p>
            <p><InlineLink href="https://buildingchanges.org">Building Changes</InlineLink></p>
            <p><InlineLink href="https://www.nwirp.org">Northwest Immigration Rights Project</InlineLink></p>
            <p><InlineLink href="https://foodlifeline.org">Food Lifeline</InlineLink></p>
            <p><InlineLink href="https://bikeworks.org">Bike Works Seattle</InlineLink></p>
            <p><InlineLink href="https://www.pasadosafehaven.org">Pasado's Safe Haven</InlineLink></p>
        </PageContent>
    );
}