import React from "react";
import styled from 'styled-components'
import {media} from "../mixins/media";
import RsvpIcon from "../img/menu-icons/rsvp.png";
import Reception from "../img/menu-icons/reception.png";
import Registry from "../img/menu-icons/registry.png";
import {NavLink} from "react-router-dom";
import {colors} from "../constants/colors";
import AboutUsSVG from "./AboutUsSVG";
import TravelSVG from "./TravelSVG";

const MenuWrap = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  align-items: center;
  max-width: 832px;

  & > a {
    text-decoration: none;
  }

  img, .menu-icon-wrap {
    display: none;
  }

  a.active img {
    display: block;
  }

  a.active .menu-icon-wrap {
    display: block;
  }

  a.active span.rsvp {
    color: ${colors.hex.green};
  }

  a.active span.reception {
    color: ${colors.hex.blue};
  }

  a.active span.travel {
    color: ${colors.hex.terracotta};
  }

  a.active span.about-us {
    color: ${colors.hex.sand};
  }

  a.active span.registry {
    color: ${colors.hex.blue};
  }
`;


const MenuItemWrap = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 2vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 20px;

  ${media.sm} {
    height: 40px;
  }

  ${media.md} {
    font-size: 1.8vw;
  }

  ${media.lg} {
    font-size: 1.2vw;
  }

  span {
    color: black;
  }
`;

const MenuIcon = styled.img`
  width: 12px;
  height: auto;

  ${media.sm} {
    width: 25px;
  }
`
const MenuIconWrap = styled.div`
  width: 12px;
  height: auto;

  ${media.sm} {
    width: 25px;
  }
`

export default function Menu() {
    return (
        <MenuWrap id="mainMenu">
            <NavLink to="/reception">
                <MenuItemWrap>
                    <MenuIcon src={Reception} alt="reception menu icon"/>
                    <span className="reception">Schedule</span>
                </MenuItemWrap>
            </NavLink>
            <NavLink to="/rsvp">
                <MenuItemWrap>
                    <MenuIcon src={RsvpIcon} alt="rsvp menu icon"/>
                    <span className="rsvp">boat rsvp</span>
                </MenuItemWrap>
            </NavLink>
            <NavLink to="/travel">
                <MenuItemWrap>
                    <MenuIconWrap className="menu-icon-wrap">
                        <TravelSVG/>
                    </MenuIconWrap>
                    <span className="travel">travel</span>
                </MenuItemWrap>
            </NavLink>
            <NavLink to="/about-us">
                <MenuItemWrap>
                    <MenuIconWrap className="menu-icon-wrap">
                        <AboutUsSVG/>
                    </MenuIconWrap>
                    <span className="about-us">c + b</span>
                </MenuItemWrap>
            </NavLink>
            <NavLink to="/registry">
                <MenuItemWrap>
                    <MenuIcon src={Registry} alt="registry menu icon"/>
                    <span className="registry">registry</span>
                </MenuItemWrap>
            </NavLink>
        </MenuWrap>
    );
}
