import React, {useRef} from "react";
import styled from "styled-components";
import SidebarCurvedText from "./SidebarCurvedText";
import {media} from "../mixins/media";
import {spacing} from "../constants/spacing";
import ScrollToTopOfMenu from "../hooks/ScrollToTopOfMenu";
import {colors} from "../constants/colors";

const PageOuterWrap = styled.div`
  background: ${(props: PageContentProps) => props.background};
`

const PageContentWrap = styled.div`
  background: ${(props: PageContentProps) => props.background};
  width: 100%;
  display: grid;
  gap: 0 ${spacing.gap.xs};
  grid-template-columns: 10% 1fr 10%;
  padding: 25px ${spacing.gutterPadding.xs};
  max-width: ${spacing.pageWrapWidth};
  margin: auto;

  ${media.sm} {
    gap: 0 ${spacing.gap.sm};
    padding: 55px ${spacing.gutterPadding.sm};
  }

  ${media.md} {
    gap: 0 ${spacing.gap.md};
    padding: 55px ${spacing.gutterPadding.md};
  }

  ${media.lg} {
    gap: 0 ${spacing.gap.lg};
    padding: 55px ${spacing.gutterPadding.lg};
  }

  ${media.xl} {
    gap: 0 ${spacing.gap.xl};
    padding: 55px ${spacing.gap.xl};
  }

  p {
    color: ${colors.hex.white};
  }
`;

const ContentWrap = styled.div`
  grid-area: page-content;
  grid-column: 2 / 3;
  overflow: auto;
`

interface PageContentProps {
    background: string;
    curvedTextColor?: string;
}

export default function PageContent(props: React.PropsWithChildren<PageContentProps>) {
    ScrollToTopOfMenu();
    const contentWrapRef = useRef(null);
    return (
        <PageOuterWrap {...props}>
            <PageContentWrap {...props}>
                <SidebarCurvedText rotated left {...props} contentWrapRef={contentWrapRef}/>
                <ContentWrap ref={contentWrapRef}>
                    {props.children}
                </ContentWrap>
                <SidebarCurvedText {...props} contentWrapRef={contentWrapRef}/>
            </PageContentWrap>
        </PageOuterWrap>
    );
}