import React from "react";
import styled from "styled-components";
import logo from "../img/logo-wedding@3x.png";
import {useHistory} from "react-router-dom";

const WeddingLogo = styled.img.attrs({
    src: `${logo}`
})`
  max-width: 367px;
  width: 40%;
  height: auto;
  margin-top: 20px;
  cursor: pointer;
`;


export default function TopLogo() {
    const history = useHistory();

    function onClick() {
        history.push("/");
    }

    return (
        <WeddingLogo onClick={onClick}/>
    );
}
