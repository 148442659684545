import React from "react";
import {colors} from "../constants/colors";
import PageContent from "./PageContent";
import CustomHeading from "./CustomHeading";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const ScheduleBlock = styled.div`
  margin-bottom: 50px;
`;

const ReceptionParagraph = styled.div`
  color: white;

  a {
    color: white;
    text-decoration: underline;
  }
`;

export default function Reception() {
    return (
        <PageContent background={colors.hex.blue}>
            <ScheduleBlock>
                <CustomHeading customHeadingText="JANUARY 6TH, 2022"/>
                <ReceptionParagraph>
                    <h2>Boat Cruise</h2>
                    <p>Set sail on a private catamaran, which will include lunch, drinks, and water activities hosted by
                        Caitlin's parents. This is technically a booze cruise, so prepare for excessive fun!
                        If this event isn't for you, there are alternative activities in the "Travel" section we
                        recommend. Exact details will be provided via email for those who <NavLink
                            to="/rsvp">RSVP</NavLink>.
                    </p>
                    <p>Departure location: TBD</p>
                    <p>Time: All day</p>
                </ReceptionParagraph>
            </ScheduleBlock>
            <ScheduleBlock>
                <CustomHeading customHeadingText="JANUARY 8TH, 2022"/>
                <ReceptionParagraph>
                    <h2>Reception</h2>
                    <ReceptionParagraph>
                        <p>Location: Villa Valentin, Sayulita</p>
                        <p>5-630pm cocktail hour</p>
                        <p>7-930pm dinner and dessert</p>
                        <p>930pm-midnight dancing</p>
                        <p>late night snacks</p>
                    </ReceptionParagraph>
                </ReceptionParagraph>
                <ReceptionParagraph>
                    <p>The reception will be adults only. Caitlin and Benny are excited to celebrate their wedding
                        with friends and family in Sayulita. They have
                        chosen to have a private ceremony at an earlier date.</p>
                </ReceptionParagraph>
                <ReceptionParagraph>
                    <p>Shuttles from the town square to Villa Valentin will be provided. Departures will begin at
                        4:30pm. Return service included. We do not recommend
                        walking to the venue or driving a golf cart.</p>
                </ReceptionParagraph>
            </ScheduleBlock>
            <ScheduleBlock>
                <CustomHeading customHeadingText="JANUARY 9TH, 2022"/>
                <ReceptionParagraph>
                    <h2>Beach day!</h2>
                    <p>Location: Playa de los Muertos</p>
                    <p>Time: 11am - 3pm</p>
                    <p>Come join us for a casual beach hang and swim sesh. BYOB. BYOF.</p>
                </ReceptionParagraph>
            </ScheduleBlock>
        </PageContent>
    );
}