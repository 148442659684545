import React from "react";
import CustomHeading from "./CustomHeading";
import PageContent from "./PageContent";
import {colors} from "../constants/colors";
import styled from "styled-components";
import {media} from "../mixins/media";
import InlineLink from "./InlineLink";

const TravelBlurbWrap = styled.div`
  display: block;

  h2 {
    text-align: center;
  }

  color: ${colors.hex.white};
`;

const TravelBlockWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  ${media.sm} {
    justify-content: space-between;
  }

  font-size: 1em;
  color: ${colors.hex.white};

  ${media.xs} {
    font-size: 1em;
  }
`;

const TravelBlock = styled.div`
  flex: 0 1 100%;
  text-align: left;
  max-width: 100%;
  margin-bottom: 30px;

  ${media.sm} {
    flex: 0 1 45%;
  }

  ${media.lg} {
    flex: 0 1 30%;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const MapSectionWrap = styled.div` {
  margin-top: 200px;
  color: white;

  iframe {
    border: 10px white solid;
  }
}`;

type TravelLink = {
    name: string;
    url: string;
};

export default function Travel() {

    const eatLinks: TravelLink[] = [
        {
            name: "Mary's",
            url: "https://www.tripadvisor.com/Restaurant_Review-g445056-d3929657-Reviews-Mary_s-Sayulita_Pacific_Coast.html",
        },
        {
            name: "Yeikame",
            url: "https://www.tripadvisor.com/Restaurant_Review-g445056-d5994238-Reviews-Yeikame_Traditional_Mexican-Sayulita_Pacific_Coast.html",
        },
        {
            name: "ChocoBanana",
            url: "https://www.tripadvisor.com/Restaurant_Review-g445056-d2231863-Reviews-ChocoBanana-Sayulita_Pacific_Coast.html",
        },
        {
            name: "Orangy",
            url: "https://www.tripadvisor.com/Restaurant_Review-g445056-d2273336-Reviews-Orangy-Sayulita_Pacific_Coast.html",
        },
        {
            name: "Miscelanea",
            url: "https://www.tripadvisor.com/Restaurant_Review-g445056-d12712980-Reviews-Miscelanea_Sayulita-Sayulita_Pacific_Coast.html",
        }
    ];

    const drinkLinks: TravelLink[] = [
        {
            name: "Sayulita Wine Shop",
            url: "https://sayulitawineshop.mx/",
        },
        {
            name: "Bar Le Zouave",
            url: "http://www.hotelhafa-sayulita.com/bar.html",
        },
        {
            name: "CAVA",
            url: "https://www.tripadvisor.com/Restaurant_Review-g445056-d7930806-Reviews-CAVA-Sayulita_Pacific_Coast.html",
        },
        {
            name: "Las Sirenas",
            url: "https://www.yelp.com/biz/las-sirenas-sayulita",
        }
    ];

    const shopLinks: TravelLink[] = [
        {
            name: "Evoke the Spirit",
            url: "https://evokethespirit.com/",
        },
        {
            name: "Manyana",
            url: "https://www.yelp.com/biz/manyana-sayulita-2",
        },
        {
            name: "Revolucion Del Sueno",
            url: "https://www.yelp.com/biz/revolucion-del-sueno-sayulita",
        },
    ];

    const relaxLinks: TravelLink[] = [
        {
            name: "Beaches",
            url: "https://www.sayulitalife.com/sayulita-beaches",
        },
        {
            name: "Yoga",
            url: "https://www.tripadvisor.com/Attraction_Review-g445056-d14460767-Reviews-Yoga_Los_Suenos-Sayulita_Pacific_Coast.html",
        },
        {
            name: "Spas",
            url: "https://www.sayulitalife.com/spa",
        },
    ];

    const exploreLinks: TravelLink[] = [
        {
            name: "Surfing lessons",
            url: "https://www.lunazulsurfing.com/",
        },
        {
            name: "Day Trip to San Francisco",
            url: "https://www.tripadvisor.com/Tourism-g552077-San_Francisco_Pacific_Coast-Vacations.html",
        },
        {
            name: "Fishing",
            url: "https://www.sayulitalife.com/sayulitafishing",
        },
        {
            name: "Hiking",
            url: "https://www.sayulitalife.com/sayulitahiking",
        },
        {
            name: "Paddle Boarding",
            url: "https://www.sayulitalife.com/paddleboarding",
        },
    ];

    const whereToStayLinks: TravelLink[] = [
        {
            name: "Airbnb",
            url: "https://www.airbnb.com/s/Sayulita--Mexico/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_dates%5B%5D=july&flexible_trip_dates%5B%5D=june&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&query=Sayulita%2C%20Mexico&place_id=ChIJjek96mIWIYQRTOjR5sHAMxQ&checkin=2022-01-07&checkout=2022-01-10&disable_auto_translation=false&source=structured_search_input_header&search_type=autocomplete_click",
        },
        {
            name: "Playa Escondida",
            url: "https://playa-escondida.com/",
        },
        {
            name: "Amor Boutique Hotel",
            url: "https://www.amorboutiquehotel.com/",
        },
        {
            name: "Oz Sayulita",
            url: "https://en.ozsayulita.com.mx/",
        },
    ];


    return (
        <PageContent background={colors.hex.terracotta}>
            <CustomHeading customHeadingText="CAITLIN AND BENNY'S"/>
            <CustomHeading customHeadingText="SAYULITA TRAVEL TIPS"/>
            <TravelBlurbWrap>
                <h2>Why Sayulita?</h2>
                <p>Caitlin discovered Sayulita 10 years ago thanks to her friend Kate. Since then it's become our go-to
                    destination for family gatherings and relaxing vacations. We love this town so much and are excited
                    to share it with our friends and family. Learn more about Sayulita <InlineLink
                        href="https://www.sayulitalife.com/sayulitainfo">here</InlineLink>.</p>

                <h2>Getting to Sayulita</h2>
                <p>Sayulita is about 40 minutes north of Puerto Vallarta. We recommend flying into Puerto Vallarta and
                    taking a car service to the town. You must reserve transportation in advance. Many villas will offer
                    transportation as part of your reservation, or you can find additional options <InlineLink
                        href="https://www.sayulitalife.com/taxi_transportation">here</InlineLink>.
                    For those who are looking for a real adventure,
                    you can also take the bus for about $5 (we’ve done it!). More information on all types of
                    transportation options can be found <InlineLink
                        href="https://sayulitainsider.com/sayulita-from-puerto-vallarta-airport/">here</InlineLink>.
                </p>

                <p>If you're staying in a villa and want to do your own cooking we recommend you stop for groceries on
                    your way in to town. This is common and most town car or shuttle services are very familiar
                    with this ask. We also recommend enjoying the town's many restaurants!</p>

                <h2>Staying in Sayulita</h2>
                <p> Sayulita accommodations tend to be villas. Even properties listed as "hotels" tend to be
                    condominium-style villas. There are only a few hotels available (Villa Amor, Amor Boutique Hotel,
                    Sayulinda Hotel, Playa Escondida).
                    We recommend using Sayulita Life to find a villa that fits your group size - there are plenty of
                    options for individuals and couples as well. Sayulita Life has the most properties, typically more
                    than Airbnb. We've provided a list and map of villas and hotel rooms by group size. Please click on
                    "…And More" under the "Where to Stay" section below to view the full list.</p>

                <p>Sayulita is built on a hill. Streets southeast of Avenue del Palmar are likely hillside. You may want
                    to use Google Streetview to determine how far and steep a property is on the hill if that's a
                    concern.
                    Please only book villas and hotels in the Sayulita area - We do not recommend booking hotels in
                    Puerto Vallarta, Punta Mita, or San Francisco.</p>

                <h2>Getting Around</h2>
                <p>Sayulita is a small town that is extremely walkable, therefore we do not recommend renting a car
                    during your stay. However it’s very common to rent golf carts to get around and it’s super fun. We
                    highly recommend the experience. We recommend <InlineLink
                        href="https://www.sayulitalife.com/golf-cart-rentals">booking a golf cart</InlineLink> prior to
                    your arrival in town as they can sell out.</p>

                <p>You may be able to provide your own transportation to and from our venue if you rent a golf cart,
                    though we recommend our shuttle service the night of our reception as roads get dark quickly and can
                    be tough to navigate at night.</p>
            </TravelBlurbWrap>
            <TravelBlockWrap>
                <TravelBlock>
                    <h2>Eat</h2>
                    {createLinks(eatLinks)}
                </TravelBlock>
                <TravelBlock>
                    <h2>Drink</h2>
                    {createLinks(drinkLinks)}
                </TravelBlock>
                <TravelBlock>
                    <h2>Shop</h2>
                    {createLinks(shopLinks)}
                    <div>
                        <a href="https://www.sayulitalife.com/sayulita-shopping" target="_blank" rel="noopener noreferrer">...And More</a>
                    </div>
                </TravelBlock>
                <TravelBlock>
                    <h2>Relax</h2>
                    {createLinks(relaxLinks)}
                </TravelBlock>
                <TravelBlock>
                    <h2>Explore</h2>
                    {createLinks(exploreLinks)}
                </TravelBlock>
                <TravelBlock>
                    <h2>Where to Stay</h2>
                    {createLinks(whereToStayLinks)}
                    <div>
                        <a href="https://docs.google.com/spreadsheets/d/1kK5d-Lx5Je-mda41HrYoZmyS7uXKtwPBYKUmBbUEVxc/edit#gid=0"
                           target="_blank" rel="noopener noreferrer">...And More</a>
                    </div>
                </TravelBlock>
            </TravelBlockWrap>

            <MapSectionWrap>
                <h2>Below is a map to important venues, recommended stays and additional restaurants.</h2>
                <iframe src="https://www.google.com/maps/d/embed?mid=1N7mxE-b0DkoWptWqDWfs1UKWKCyz8g8x" width="100%"
                        height="480"
                        title="Sayulita"/>
            </MapSectionWrap>
        </PageContent>
    );

    function createLinks(listOfLinks: TravelLink[]) {
        return listOfLinks.map((item: TravelLink, idx: number) => <div key={idx}><a href={item.url}
                                                                                    target="_blank" rel="noopener noreferrer">{item.name}</a>
        </div>);
    }
}