import React, {forwardRef} from "react";

export interface CaitlinAndBennyCurvedVerticalSVGProps {
    color?: string;
    height?: string;
}

export type Ref = SVGSVGElement;
export const CaitlinAndBennyCurvedVerticalSVG = forwardRef<Ref, CaitlinAndBennyCurvedVerticalSVGProps>((props: CaitlinAndBennyCurvedVerticalSVGProps, ref) => (
    <svg width="100%"
         height={props.height}
         viewBox="-35 0 90 600"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         ref={ref}>
        <defs>
            <path id="MyVerticalPath"
                  d="m 0 0 C -45 37.5 -45 112.5 0 150 C 45 187 45 263 0 300 C -45 337.5  -45 412.5 0 450 C 45 487.5 45 562.5 0 600"/>
        </defs>

        <use xlinkHref="#MyVerticalPath" fill="none" stroke="none"/>

        <text fontSize="22" fill={props.color || 'white'}>
            <textPath xlinkHref="#MyVerticalPath">
                <tspan dx="0">CAITLIN AND BENNY ARE GETTING MARRIED!</tspan>
            </textPath>
        </text>
    </svg>
));
