import {useEffect} from "react";

export const scrollToMenuTop = () => {
    const mainMenu = document.getElementById("mainHeader");
    if (mainMenu) {
        const mainMenuRectTop = mainMenu.offsetTop + mainMenu.offsetHeight;
        window.scroll({top: mainMenuRectTop, left: 0, behavior: 'smooth'});
    }
}

export default function ScrollToTopOfMenu() {
    useEffect(() => {
        scrollToMenuTop();
    }, []);
}