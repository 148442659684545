import React from "react";
import styled from "styled-components";
import {colors} from "../constants/colors";
import {split} from "lodash";
import {media} from "../mixins/media";

// We are passing a default theme for Buttons that arent wrapped in the ThemeProvider

const CustomHeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'WTMonarchNova', serif;
  font-size: 6vw;
  text-transform: uppercase;
  color: ${props => props.theme.main};
  ${media.sm} {
    font-size: 5.7vw;
  }
  ${media.md} {
    font-size: 5.5vw;
  }
  ${media.xl} {
    font-size: 4.7vw;
  }
`;

CustomHeadingWrap.defaultProps = {
    theme: {
        main: `${colors.hex.white}`
    }
}

export interface CustomHeadingProps {
    customHeadingText?: string
}

export default function CustomHeading(props: React.PropsWithChildren<CustomHeadingProps>) {
    const theSplitText = split(props.customHeadingText, " ");
    return (
        <CustomHeadingWrap>
            {theSplitText.map((value: string, index: number) => {
                return <span key={index}>{value}</span>
            })}
        </CustomHeadingWrap>
    )
}