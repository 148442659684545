// based off of ttps://usehooks.com/useWindowSize/
import React, {useEffect, useState} from "react";

// Define general type for useWindowSize hook, which includes width and height
export interface Size {
    width: number | undefined;
    height: number | undefined;
}

// Hook
export default function useComponentSize(contentWrapRef: React.RefObject<any>): Size {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setComponentSize] = useState<Size>({
        width: calculateTrueSize(contentWrapRef).width,
        height: calculateTrueSize(contentWrapRef).height,
    });

    function calculateTrueSize(parentNodeRef: React.RefObject<any>): Size {
        // Set component width/height to state
        const parentNode = parentNodeRef.current;
        if (parentNode) {
            const nodeList = parentNode.childNodes;
            let finalWidth = 0
            let finalHeight = 0
            for (let i = 0; i < nodeList.length; i++) {
                finalWidth += nodeList[i].clientWidth;
                finalHeight += nodeList[i].clientHeight;
            }
            return {
                width: finalWidth,
                height: finalHeight,
            }
        }
        return {
            width: undefined,
            height: undefined,
        }
    }

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            const trueSize = calculateTrueSize(contentWrapRef);
            setComponentSize({
                width: trueSize.width,
                height: trueSize.height,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}